<template>
  <!-- 信息录入--用户详情 -->
  <div class="userDetail">
    <!-- 板块中心区域 -->
    <div class="userDetail_mid ">
      <div class="userDetail_header">
        <div class="head">
          <h4>{{inform.gname}}
            <p v-if="inform.status == 1">潜在用户</p>
            <p v-else-if="inform.status == 2">已合作</p>
            <p v-else>已放弃</p>
          </h4>
          <img src="../../assets/image/user_detail/group_140.png" alt="" @click="returnBack">
        </div>
        <div class="detail_content">
          <!-- 新建用户中会有 现在未完成等新建用户完成后就可以完成  私企 详细地址 联系电话 都要新建用户完成后-->
          <p>营业执照号<span>{{inform.cardid}}</span></p>
          <p>公司性质<span style="margin-left:2.3vw;">私企</span></p>
          <p>所属地区<span>{{inform.prov}}{{ inform.city }}</span></p>
          <p>详细地址<span>{{ inform.addr }}</span></p>
          <p>所属代理商<span>{{inform.cid}}</span></p>
          <p>项目对接人<span>{{ inform.guser }}</span></p>
          <p>联系电话<span>{{ inform.gphone }}</span></p>
        </div>
      </div>
      <!-- 信息区域 -->
      <div class="userDetail_inform">
        <!-- 信息详情 -->
        <div class="inform_detail">
          <h3>信息详情</h3>
          <!-- 编辑和保存按钮 -->
          <div class="detail_btn">
            <el-button size="medium" :type="isType" @click="edit" ref="btn1" class="btn2">编辑</el-button>
            <el-button size="medium" :type="isType" @click="save" ref="btn2" class="btn1"
              style="background-color:#0666F5;color:#fff">保存</el-button>
          </div>
        </div>
        <!-- 版心区域 -->
        <div class="edition_heart">
          <!-- 基本信息 -->
          <div class="basic_inform">
            <!-- 标题信息 -->
            <div class="inform_title">
              <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
              <span>基本信息</span>
            </div>
            <!-- 表单区域 -->
            <div class="basic_content">
              <el-form ref="inform" label-width="100px">
                <!-- 公司名称 -->
                <el-form-item label="公司名称">
                  <el-input v-model="inform.gname" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 所属代理商 -->
                <el-form-item label="所属代理商">
                  <el-input v-model="inform.cid" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 创建日期 -->
                <el-form-item label="创建日期">
                  <el-date-picker v-model="inform.dtime" type="datetime" placeholder="选择日期时间" style="width:100%;"
                    :disabled='isDisable'>
                  </el-date-picker>
                </el-form-item>
                <!-- 跟进状态 -->
                <el-form-item label="跟进状态" prop="followStatus">
                  <el-select v-model="inform.status" placeholder="请选择跟进状态" :disabled='isDisable' style="width:100%;">
                    <el-option :label="item.label" :value="item.value" v-for="item in followStatusArr"
                      :key="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 销售员 -->
                <el-form-item label="销售员">
                  <el-input v-model="inform.saler" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 联系电话 -->
                <el-form-item label="联系电话">
                  <el-input v-model="inform.salephone" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 备注说明 -->
                <el-form-item label="备注说明">
                  <el-input v-model="inform.beizhu" class="remark" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 占位 -->
                <el-form-item>
                  <div></div>
                </el-form-item>
                <!-- 合同上传 -->
                <el-form-item label="合同上传" class="upload_agreement">
                  <el-upload class="upload-demo upHetong" action="https://api.fediot.net/ele/upload/agent/file/up"
                    :on-change="upHetong" ref="uploadFile" :disabled='isDisable' :show-file-list="true"
                    :file-list="fileList" :on-preview="handlePreview" :on-remove="handleRemove"
                    :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :auto-upload=false>
                    <el-button icon="el-icon-upload2">文件上传</el-button>
                    <div slot="tip" class="el-upload__tip">*仅支持jpg、jpeg、pdf格式</div>
                  </el-upload>
                </el-form-item>

                <!-- 证件1上传 -->
                <el-form-item label="证件上传" class="documents_upload" :disabled='isDisable'>
                  <el-upload class="avatar-uploader" action="https://api.fediot.net/ele/upload/agent/file/up"
                    :show-file-list="false" :auto-upload=false :on-change="upWenjian" :disabled='isDisable'
                    style="margin-right:15px">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" style="width:84px;height:47px;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <!-- 证件2 -->
                  <el-upload class="avatar-uploader" action="https://api.fediot.net/ele/upload/agent/file/up"
                    :show-file-list="false" :auto-upload='false' :on-change="upZhengjian" :disabled='isDisable'>
                    <img v-if="zhengjainUrl" :src="zhengjainUrl" class="avatar" style="width:84px;height:47px;">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 授权信息 -->
          <div class="authorization_inform">
            <div class="authorization_title">
              <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
              <span>授权信息</span>
            </div>
            <!-- 表单区域 -->
            <div class="authorization_ipt">
              <el-form ref="authorizationForm" label-width="100px">
                <!-- 配置账号 -->
                <el-form-item label="配置账号">
                  <el-input v-model="user.cuser" :disabled='isDisable'></el-input>
                  <el-checkbox v-model="user.power" :disabled='isDisable' @change="changeVal">是否同时作为电工账号使用</el-checkbox>
                </el-form-item>
                <!-- 登录号码 -->
                <el-form-item label="登录号码">
                  <el-input v-model="user.phone" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 授权有效期 -->
                <el-form-item label="授权有效期">
                  <el-date-picker v-model="user.ctime" type="datetime" placeholder="选择日期时间" :disabled='isDisable'
                    style="width:100%">
                  </el-date-picker>
                </el-form-item>
                <!-- 电工账号 -->
                <el-form-item label="电工账号">
                  <el-input v-model="inform.gjuser" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 登录号码 -->
                <el-form-item label="登录号码">
                  <el-input v-model="inform.gjphone" :disabled='isDisable'></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 设备数量 -->
          <div class="device_number">
            <div class="device_title">
              <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
              <span>设备数量</span>
            </div>
            <!-- 表单区域 -->
            <div class="device_ipt">
              <el-form ref="deviceForm" label-width="100px">
                <!-- 网关数 -->
                <el-form-item label="网关数">
                  <el-input v-model="mach.net" :disabled='isDisable'></el-input>
                </el-form-item>
                <!-- 回路数 -->
                <el-form-item label="回路数">
                  <el-input v-model="mach.mach" :disabled='isDisable'></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { fileUpload, editUser, fetchSignUser } from "@/utils/api";
import { Message } from "element-ui";
export default {
  components: {

  },
  data () {
    return {
      inform: {},
      user: {},//用户信息
      mach: {},//回路数和网关数
      isDisable: true,//表单是否被禁用
      isType: '',
      imgUrl: '',//合同是图片
      imageUrl: '',//证件1地址
      zhengjainUrl: '',//证件2地址
      fileList: [],//文件列表
      form: {
        conUrl: [],//合同地址
        img1_url: '',//证件1
        img2_url: '',//证件2
      },
      fileArr: [],
    };
  },
  computed: {
    ...mapState('userDetail', ['authorizationForm', 'deviceForm', 'followStatusArr'])
  },
  mounted () {
    let informs = JSON.parse(sessionStorage.getItem('informs'));
    this.inform = informs.data;
    this.getSignUser()
  },

  methods: {
    // 获取单个用户的信息
    getSignUser () {
      fetchSignUser({ id: this.inform.id }).then(res => {
        this.inform = res.data.data.data
        this.user = res.data.data.user
        this.mach = res.data.data.mach;
        console.log(res)
      }).catch(err => {
        return console.log(err)
      })
    },
    // 点击已上传文件--可以用来查看文件
    handlePreview (file) {
      let url = URL.createObjectURL(file.raw)
      console.log(url)
    },
    //文件列表移除文件时的钩子
    handleRemove (file, fileList) {

    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 更改是否作为电工账户使用
    changeVal () {
      if (this.user.power) {//电工账号--当他为false 不能是电工账号 true时可以为电工账号
        this.user.cuser = this.user.phone
      }
    },
    //返回
    returnBack () {
      this.$router.go(-1)
    },
    // 证件1上传
    upWenjian (file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.img1_url = file.raw
      fileUpload({ file: this.form.img1_url, uid: 2, id: 20, type: 2 }, true).then(res => {
        if (res.data.code == 1) {
          this.fileArr.push(res.data.data.data)
          this.imageUrl = this.$URL + res.data.data.data;

        }
      }).catch(err => {
        return console.log(err);
      })


    },
    //证件2的上传
    upZhengjian (file) {
      if (!file) {
        return
      } else {
        let { size, type } = file.raw
        if (size > 2 * 1024 * 1024) { //文件大小不得超过2M
          Message.warning('文件大小不能超过2M');
          return false;
        }
        //判断文件类型
        let typeArr = ["image/jpg", "image/jpeg", "image/png", 'image/gif']
        if (typeArr.indexOf(type) == -1) {
          Message.warning('仅支持jpg、jpeg、pdf格式', 'warning');
          return false;
        }
        this.zhengjainUrl = URL.createObjectURL(file.raw);
        this.form.img2_url = file.raw;
        fileUpload({ file: this.form.img2_url, uid: 2, id: 20, type: 2 }, true).then(res => {
          if (res.data.code == 1) {
            this.fileArr.push(res.data.data.data)
            this.zhengjainUrl = this.$URL + res.data.data.data;
          }
        }).catch(err => {
          return console.log(err);
        })
      }
    },
    // 合同上传
    upHetong (file) {
      let { size, type } = file.raw
      let { uid } = file
      if (size > 2 * 1024 * 1024) { //文件大小不得超过2M
        let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
        this.$refs.uploadFile.uploadFiles.splice(idx, 1)
        return this.$message.error('文件大小不能超过2M');
      }
      // 判断文件类型
      let typeArr = ["image/jpg", "image/jpeg", "image/png", 'application/pdf']
      if (typeArr.indexOf(type) == -1) {
        let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
        this.$refs.uploadFile.uploadFiles.splice(idx, 1)
        return this.$message.error('仅支持jpg、jpeg、pdf格式');
      }
      this.fileurl = URL.createObjectURL(file.raw)
      this.imgUrl = file.raw
      // 文件上传
      fileUpload({ file: this.imgUrl, uid: 2, id: 20, type: 1 }, true).then(res => {
        if (res.data.code == 1) {
          this.form.conUrl.push(res.data.data.data)
        }
      }).catch(err => {
        return console.log(err);
      })
    },
    //保存
    save () {
      this.isDisable = true;
      // 先获取按钮的样式
      let btn1 = document.querySelector('.btn1')
      let btn2 = document.querySelector('.btn2')
      btn1.style.backgroundColor = '#0666F5';
      btn1.style.color = '#fff';
      btn2.style.backgroundColor = '#fff';
      btn2.style.color = '#171717';
      this.$refs.btn1.type = '';
      // 修改用户信息 

      // 将合同信息和证件信息 进行赋值
      this.inform.hetong = [...this.form.conUrl]
      this.inform.zhengjian = [...this.fileArr]
      let obj = { ...this.inform, ...this.user }
      obj = { ...obj, ...this.mach }
      console.log(obj.id)
      editUser({
        cardid: obj.cardid,
        gname: obj.gname,
        gurl: obj.gurl,
        guser: obj.guser,
        gphone: obj.gphone,
        gjuser: obj.gjuser,
        gjphone: obj.gjphone,
        prov: obj.prov,
        city: obj.city,
        addr: obj.addr,
        gtype: obj.gtype,
        beizhu: obj.beizhu,
        hetong: obj.hetong,
        zhengjian: obj.zhengjian,
        status: obj.status,
        saler: obj.saler,
        salephone: obj.salephone,
        lon: obj.lon,
        lat: obj.lat,
        id: obj.id
      }).then(res => {
        console.log(res.data)
        if (res.data.code == 1) {
          this.$message.success('更新成功')
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    //编辑
    edit () {
      this.isDisable = false
      let btn1 = document.querySelector('.btn1')
      let btn2 = document.querySelector('.btn2')
      btn2.style.backgroundColor = '#0666F5';
      btn2.style.color = '#fff';
      btn1.style.backgroundColor = '#fff';
      btn1.style.color = '#171717';
      this.$refs.btn2.type = '';
    }
  },
};
</script>

<style  scoped>
/* 滚动条的样式 */
.userDetail_inform::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}
.userDetail_inform::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.userDetail_inform::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.userDetail {
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.userDetail .userDetail_mid {
  width: 83.8vw;
  height: 87vh;
}
/* 头部--开始 */
.userDetail_mid .userDetail_header {
  width: 100%;
  height: 16.3vh;
  background-color: #fff;
}
.head {
  display: flex;
  justify-content: space-between;
}
.head h4 {
  margin: 2.6vh 0 0 1.875vw;
  font-size: 20px;
  font-family: "SHSCN_Bold";
}
/* 是否合作 */
.head h4 p {
  display: inline-block;
  font-size: 11px;
  background-color: #ecf1ff;
  color: #0666f5;
  font-family: "SHSCN_Regular";
  font-weight: normal;
  padding: 5px 8px 5px 8px;
  border-radius: 25%;
  margin-left: 0.73vw;
  border: 1px dashed #333;
}
/* 返回 */
.head img {
  width: 63.74px;
  height: 18px;
  margin: 8px 10px 0 0;
  cursor: pointer;
}
.detail_content {
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  margin: 0 0 0 1.875vw;
  font-family: "SHSCN_Regular";
}
.detail_content p {
  color: #ababab;
  margin: 2.2vh 0 0 0;
}
.detail_content span {
  color: #171717;
  margin-left: 1.25vw;
}
/* 头部--结束 */

/* 信息详情--开始 */
.userDetail_mid .userDetail_inform {
  width: 100%;
  height: 71.8vh;
  background-color: #fff;
  margin-top: 2vh;
  /* overflow: hidden; */
  overflow-y: scroll;
}
/* 信息详细样式 */
.inform_detail {
  margin: 2.6vh 0 0 1.875vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inform_detail h3 {
  font-family: "SHSCN_Bold";
  font-size: 2.5vh;
}
.inform_detail .detail_btn {
  margin-right: 1.45vw;
}
/* 版心区域 */
.edition_heart {
  width: 92%;
  margin: 2vh 0 0 3vw;
}
/* 基本信息--标题 */
.inform_title img {
  vertical-align: top;
}
.inform_title span {
  font-family: "SHSCN_Regular";
  display: inline-block;
  margin-left: 0.3vw;
}
/* 合同上传 */
.upHetong /deep/ .el-upload {
  width: 84px;
  height: 14px;
}
.upHetong /deep/ .el-button {
  width: 88px;
  height: 32px;
  padding: 0 0;
  /* border: 1px dashed #0666F5; */
  border: none;
  margin-bottom: 5px;
}
.upHetong /deep/ span {
  font-size: 16px;
  color: #0666f5;
}
.upHetong /deep/ .el-icon-upload2 {
  font-size: 16px;
  color: #0666f5;
}
/* 基本信息--表单区域 */
.basic_inform .basic_content,
.authorization_ipt,
.device_ipt {
  width: 90%;
  margin: 0 0 0 0.6vw;
}
.basic_content .el-form {
  display: grid;
  grid-template-columns: 25% 25% 29% 23%;
  grid-template-rows: 33% 33% 33%;
  align-items: center;
}
/*输入框的长度 */
.basic_inform /deep/ .el-form-item__label {
  font-size: 14px;
  font-family: "SHSCN_Regular";
}
/* 备注说明 */
.remark /deep/ .el-input__inner {
  width: 220%;
}
.upload_agreement img {
  width: 74px;
  height: 20px;
  border: 1px dotted #8c939d;
}
/* 合同上传 */
.el-upload__tip {
  color: #8c939d;
  margin-top: 5px;
}
.upload_agreement /deep/ .el-form-item__content {
  line-height: 20px;
}

/* 证件上传 */
.documents_upload /deep/ .avatar-uploader {
  background-image: url("../../assets/image/user_detail/group_354.png");
  background-size: 84px 47px;
  background-repeat: no-repeat;
}
.documents_upload /deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 84px;
  height: 47px;
  text-align: center;
  line-height: 47px;
  border: 1px dashed #c1c3c7;
  opacity: 0;
}
.avatar {
  width: 100%;
  height: 178px;
  display: block;
}
.documents_upload /deep/ .el-form-item__content {
  display: flex;
}

.documents_upload img {
  border: 1px dashed #c1c3c7;
}
.documents_upload .group_354 {
  margin-left: -30%;
}
/* 信息详情--结束 */
/* 授权信息--开始 */
.authorization_inform img {
  vertical-align: top;
}
.authorization_inform span {
  font-family: "SHSCN_Regular";
  display: inline-block;
  margin-left: 0.3vw;
}
.authorization_ipt .el-form {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.el-form {
  font-family: "SHSCN_Regular";
}
/* 授权信息--结束 */
/* 设备数量--开始 */
.device_title img {
  vertical-align: top;
}
.device_title span {
  font-family: "SHSCN_Regular";
  display: inline-block;
  margin-left: 0.3vw;
}
.device_ipt .el-form {
  display: grid;
  grid-template-columns: 15% 15%;
}
/* 设备数量结束 */
@media screen and (min-width: 1920px) {
  .remark /deep/ .el-input__inner {
    width: 210%;
  }
}
</style>